<script setup lang="ts">
defineLayout({
  label: 'Wyndham Cancun Layout',
  colors: {
    fields: {
      picker: true,
      palette: true,
    },
    background: {
      picker: true,
      palette: true,
    },
    foreground: {
      picker: true,
      palette: true,
    },
  },
  colorPalette: {
    '#3b4852': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#0d7d72': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#01aebe': {
      fields: true,
      background: true,
      foreground: true,
    },
  },
})

const config = {
  stacked: false,
  logoPosition: 'left', // left or center
  menuPosition: 'right', // right or center
  backgroundClass: 'bg-transparent',
  containerClasses: 'border-b border-white',
  bookNowBackground: 'bg-[#0c7d72]',
  languageSelectorText: 'text-white',
  bookNowText: 'text-white',
  booking: true,
  noSpacer: true,
  scrollTriggerDistance: 100,
  scrollBooking: true,
  scrollClass: 'bg-white',
  textClass: 'text-white',
  backdropBlur: true,
  showScrollBookingOnLoad: false,
}

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/wyndham-cancun/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/wyndham-cancun/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/wyndham-cancun/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/wyndham-cancun/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader(null, links)

const {
  resorts,
  brands,
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer, [])

provide('accentColor', { value: '#0c7d72' })

if (currentResort?.value)
  provide('currentResort', currentResort.value.data)

if (resorts?.value)
  provide('resorts', resorts.value.data)

if (brands?.value)
  provide('brands', brands.value.data)
</script>

<template>
  <WyndhamLayoutWrapper :config="config">
    <slot />
  </WyndhamLayoutWrapper>
</template>

<style lang="scss">
  @import '~/assets/css/wyndham-cancun.scss';
</style>
